<template>
  <b-form-group :invalid-feedback="failed" :label="title" :state="state">
    <v-select
      :disabled="readOnly"
      v-if="hasData"
      :options="options"
      v-model="currentCountry"
      label="value"
      :reduce="country => country.key"
      :clearable="true"
      :searchable="true">
    </v-select>
  </b-form-group>
</template>

<script>
export default {
  name: 'FieldNations',
  model: {
    prop: 'countryCode',
    event: 'input',
  },
  props: ['countryCode', 'title', 'readOnly', 'state', 'errorMessage'],
  created() {
    if (!this.$store.getters['tableUtils/hasNationList'] && !this.$store.getters['tableUtils/isLoadingNation']) {
      this.$store.dispatch('tableUtils/loadNationList').then(() => {
        console.log('loaded nations');
      });
    }
  },
  computed: {
    failed() {
      return this.errorMessage || 'Il campo è obbligatorio';
    },
    hasData() {
      return this.$store.getters['tableUtils/hasNationList'];
    },
    options() {
      return this.$store.getters['tableUtils/getNationList'];
    },
    isLoading() {
      return this.$store.getters['tableUtils/isLoadingNation'];
    },
    currentCountry: {
      get() {
        return this.countryCode;
      },
      set(country) {
        // console.log('updateSelf', country);
        this.$emit('input', country);
      },
    },
  },
  methods: {
    updateSelf(name) {
      // console.log('updateSelf', name);
      this.$emit('input', name);
    },
  },
};
</script>

<style scoped>

</style>
